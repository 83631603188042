.fc-event .fc-bg {
  z-index : 1;
  background: "#fff";
  // opacity: 0.25;
}

.fc-daygrid-event {
   opacity: 1.0;
}

// .fc-event .fc-bg {
//   opacity: 0.0 !important;
// }
